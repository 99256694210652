import { Button, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Form, Formik, useField } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startConnecting,
  startParsing,
  stopConnecting,
  stopParsing,
  updateAll,
  updateOne,
  deleteAll,
} from '../../../store/actions/parsers.actions';
import { RootState } from '../../../store/store';
import style from '../ParsersPage.module.scss';
import UpdateOne from '../ParserItems/updateOneModal';
import SettingsModal from '../ParserItems/SettingsModal';
import { stylesParserPage } from '../styles';
import { getParserSettings } from '../../../store/actions/settings.actions';
import { IParserStatus } from '../../../interfaces/IParsers';
import ErrorModal from '../ParserItems/ErrorModal';

const useStyles = makeStyles(stylesParserPage);

interface ConsoleParserPageProps {
  statuses: IParserStatus;
}

export const ConsoleParserPage: React.FC<ConsoleParserPageProps> = ({ statuses }) => {
  let textLog: any = React.createRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { message, inProgressParser, activeParser } = useSelector((state: RootState) => state.parsers);

  const isButtonDeactive = useMemo(() => {
    if (inProgressParser && statuses.parserName !== inProgressParser) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgressParser]);

  useEffect(() => {
    dispatch(startConnecting());
    return () => {
      dispatch(stopConnecting());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (textLog.current) {
      textLog.current.scrollTop = textLog.current.scrollHeight;
    }
  }, [textLog]);

  useEffect(() => {
    dispatch(getParserSettings());
  }, [dispatch]);

  const startParserCommand = () => {
    dispatch(startParsing({ parser: statuses.parserName, command: 'START' }));
  };

  const stopParserCommand = () => {
    dispatch(stopParsing({ parser: statuses.parserName, command: 'STOPPED... Process aborted by user' }));
  };

  const updateAllProducts = () => {
    dispatch(updateAll({ parser: statuses.parserName, command: 'UPDATE_ALL' }));
  };

  const updateOneProduct = (key: string) => {
    dispatch(updateOne({ parser: statuses.parserName, command: 'UPDATE_ONE', key }));
  };

  const deleteAllProducts = (key: string) => {
    dispatch(deleteAll({ parser: statuses.parserName, command: 'DELETED_ALL', key }));
  };

  const MyTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props as any);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <textarea ref={textLog} className={style.console} {...field} {...props} />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </>
    );
  };

  return (
    <div>
      <ErrorModal inProgressParser={inProgressParser} parserName={statuses.parserName}></ErrorModal>
      <Formik
        initialValues={{
          productKey: '',
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => (
          <Form className={style.mainForm}>
            <MyTextArea label="" name="console" rows="32" value={message[activeParser]} onChange={() => {}} />
            <div className={style.buttonsBox}>
              <div className={style.buttonsBoxLeft}>
                <SettingsModal
                  parserStatus={statuses.parserStatus}
                  parserName={statuses.parserName}
                  isButtonDeactive={isButtonDeactive}
                />
              </div>
              <div className={style.buttonsBoxRight}>
                <Button
                  variant="contained"
                  type="button"
                  onClick={startParserCommand}
                  className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                  disabled={isButtonDeactive || (statuses.parserStatus === 'STARTED' ? true : false)}
                >
                  Start {activeParser.toUpperCase()} Parser
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={stopParserCommand}
                  className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                  disabled={isButtonDeactive || (statuses.parserStatus === 'STARTED' ? false : true)}
                >
                  Stop {activeParser.toUpperCase()} Parser
                </Button>
                <UpdateOne
                  name="UpdateOne"
                  updateCommand={updateOneProduct}
                  parserStatus={statuses.parserStatus}
                  parserName={statuses.parserName}
                  isButtonDeactive={isButtonDeactive}
                />
                <UpdateOne
                  name="DeleteAll"
                  updateCommand={deleteAllProducts}
                  parserStatus={statuses.parserStatus}
                  parserName={statuses.parserName}
                  isButtonDeactive={isButtonDeactive}
                />
                <Button
                  variant="contained"
                  type="button"
                  onClick={updateAllProducts}
                  className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                  disabled={isButtonDeactive || (statuses.parserStatus === 'STARTED' ? true : false)}
                >
                  Update All {activeParser.toUpperCase()} Products
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
