import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, ThemeOptions } from '@material-ui/core/styles';
import Avatar, { ConfigProvider } from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { switchDarkMode } from '../../../store/actions/theme.actions';
import MobileMenuList from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { root } from '../../../api/config';

interface HeaderBarProps {
  onMailListOpen: (evt: any) => void;
  onNotificationListOpen: (evt: any) => void;
  onAccountListOpen: (evt: any) => void;
  onMobileMenuOpen: (evt: any) => void;
  mobileMenuAnchorEl: null | Element;
  allDropdownsClose: () => void;
}

const useStyles = makeStyles(
  (theme): ThemeOptions => ({
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      margin: 0,
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    nightMode: {
      color: (isDark: boolean) => (isDark ? 'rgba(0, 0, 0, 0.54)' : 'white'),
    },
    imgAvatar: {
      display: 'block',
    },
  })
);

const AppBarMenu: React.FC<HeaderBarProps> = (props) => {
  const {
    onMailListOpen,
    onNotificationListOpen,
    onAccountListOpen,
    onMobileMenuOpen,
    mobileMenuAnchorEl,
    allDropdownsClose,
  } = props;

  const isDark = useSelector((state: RootState) => state.theme.darkMode);
  const { user, avatarLink } = useSelector((state: RootState) => state.user);
  const classes = useStyles(isDark);
  const userAvatar = user ? (!avatarLink ? null : user?.avatar?.name) : null;
  const avatarSize = '30';

  const dispatch: AppDispatch = useDispatch();
  const themeToggle = () => dispatch(switchDarkMode());

  const mobileMenuList = [
    {
      ariaName: 'mails',
      nmb: 4,
      icon: <MailIcon />,
      msg: 'Messages',
      onClick: onMailListOpen,
    },
    {
      ariaName: 'notifications',
      nmb: 11,
      icon: <NotificationsIcon />,
      msg: 'Notifications',
      onClick: onNotificationListOpen,
    },
  ];

  return (
    <>
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={themeToggle}>
          <Brightness2Icon className={classes.nightMode} />
        </IconButton>
        <IconButton color="inherit" onClick={onMailListOpen}>
          <Badge overlap="rectangular" color="primary">
            <MailIcon />
          </Badge>
        </IconButton>
        <IconButton color="inherit" onClick={onNotificationListOpen}>
          <Badge overlap="rectangular" color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={onAccountListOpen}
          color="inherit"
          style={{ margin: '0', padding: '6px', width: '48px' }}
        >
          {!user ? (
            <AccountCircleIcon />
          ) : userAvatar ? (
            <Avatar
              src={`${root}/static/uploads/avatar/${userAvatar}`}
              alt="avatar"
              round={true}
              size={avatarSize}
              className={classes.imgAvatar}
            />
          ) : (
            <ConfigProvider avatarRedirectUrl="https://avatar-redirect.appspot.com">
              <Avatar
                googleId={user?.googleId}
                facebookId={user?.facebookId}
                name={`${user.lastName} ${user.firstName}`}
                round={true}
                alt="avatar"
                size={avatarSize}
              />
            </ConfigProvider>
          )}
        </IconButton>
      </div>

      <div className={classes.sectionMobile}>
        <IconButton color="inherit" onClick={themeToggle}>
          <Brightness2Icon className={classes.nightMode} />
        </IconButton>
        <IconButton aria-label="show more" aria-haspopup="true" onClick={onMobileMenuOpen} color="inherit">
          <MoreIcon />
        </IconButton>
      </div>

      <MobileMenuList
        anchorEl={mobileMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!mobileMenuAnchorEl}
        onClose={allDropdownsClose}
      >
        {mobileMenuList.map(({ ariaName, nmb, icon, msg, onClick }) => (
          <MenuItem key={msg} onClick={onClick}>
            <IconButton aria-label={`show ${nmb} new ${ariaName}`} color="inherit">
              <Badge overlap="rectangular" badgeContent={nmb} color="secondary">
                {icon}
              </Badge>
            </IconButton>
            <p>{msg}</p>
          </MenuItem>
        ))}
        <MenuItem onClick={onAccountListOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <p>Account</p>
        </MenuItem>
      </MobileMenuList>
    </>
  );
};

export default AppBarMenu;
