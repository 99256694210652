import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'reactstrap';
import { Typography } from '@material-ui/core';

import { IProductSize } from '../../interfaces/IProductSize';
import { validate } from './validate.schema';
import styles from './SizeInput.module.scss';

interface ISizeInput {
  sizes: string[];
  initialValues?: IProductSize;
  onSubmit: (values: IProductSize) => void;
}

const SizeInput: React.FC<ISizeInput> = ({ sizes, initialValues = {}, onSubmit }) => {
  const existingSizes = Object.keys(initialValues).filter((size) => sizes.includes(size));
  const missingSizes = sizes.filter((size) => !existingSizes.includes(size));
  const initialSizeValues = Object.fromEntries(sizes.map((size) => [size, initialValues[size] || '']));

  const handleSubmit = (values: { [key: string]: string }) => {
    const res = Object.entries(values).map(([key, value]) => [key, value]);
    const numericValues = Object.fromEntries(res);

    onSubmit(numericValues);
  };

  const [maxInputValue, setMaxInputValue] = useState<string>('0');

  const formik = useFormik({
    initialValues: initialSizeValues,
    validate,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const values = Object.values(formik.values).filter((v) => !!v && !isNaN(Number(v)));
    const max = values.length ? Math.max(...values.map((v) => Number(v))) : 'Не встановлено';

    setMaxInputValue(max.toString());
  }, [formik.values]);

  return (
    <div>
      <Typography className={styles.formTitle} variant="subtitle1" gutterBottom>
        На даний момент найбільший пріорітет: {maxInputValue}
      </Typography>
      <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
        <div className={styles.form}>
          {[...existingSizes, ...missingSizes].map((size, index) => {
            const allValues = Object.values(formik.values);
            const clearEmptyValues = allValues.filter((v) => v);
            const isValueExists = clearEmptyValues.filter((v) => v === formik.values[size]);

            const isValueNotNumber = isNaN(Number(formik.values[size]));
            return (
              <div className={styles.inputContainer} key={index}>
                <label
                  className={
                    isValueExists.length > 1 || isValueNotNumber
                      ? `${styles.text} ${styles.textDanger}`
                      : styles.text
                  }
                  htmlFor={size}
                >
                  {size}:{' '}
                </label>
                <input
                  className={
                    isValueExists.length > 1 || isValueNotNumber
                      ? `${styles.input} ${styles.inputDanger}`
                      : styles.input
                  }
                  type="text"
                  id={size}
                  name={size}
                  value={formik.values[size] || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  pattern="\d*"
                />
                {formik.errors[size] && formik.touched[size] && (
                  <p className={styles.error}>{formik.errors[size]}</p>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.errorContainer}>
          {formik.errors._error && <p className={styles.error}>{formik.errors._error}</p>}
        </div>

        <Button color="primary" variant="contained" type="submit" disabled={Boolean(formik.errors._error)}>
          Зберегти
        </Button>
      </form>
    </div>
  );
};

export default SizeInput;
