import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { deleteTreeCategory } from '../../../../store/actions/treeCategories.actions';
import { IChildren } from '../../../../interfaces/ITreeCategory';
import CustomConfirm from '../../../../components/CustomConfirm/CustomConfirm';

interface ICategoryInfo {
  id: number;
  name: string;
  children: IChildren[] | [];
}

interface ModalCategoryProps {
  categoryInfo: ICategoryInfo;
  handleClose: () => void;
}

const DeleteTreeCategoryModal: React.FC<ModalCategoryProps> = ({ categoryInfo, handleClose }) => {
  const dispatch: AppDispatch = useDispatch();

  const handleDeleteCategory = () => {
    dispatch(deleteTreeCategory(categoryInfo.id));
    handleClose();
  };

  function createWarningMessage(children): string {
      if (children.length) {
        return 'Категорія містить підкатегорії, їх буде також остаточно видалено! Ви впевнені, що хочете їх видалити?';
      } else {
        return 'Категорію буде остаточно видалено! Ви впевнені, що хочете її видалити?';
      }
  }

  return (
    <>
      <CustomConfirm
        openDeleteDialog={true}
        closeDeleteDialog={handleClose}
        name={`категорію ${categoryInfo.name}`}
        warning={createWarningMessage(categoryInfo.children)}
        handleDelete={handleDeleteCategory}
      />
    </>
  );
};

export default DeleteTreeCategoryModal;
