import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategoriesPriority from './Form/CategoriesPriority-form';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Chip, CircularProgress, Tooltip } from '@material-ui/core';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  changeCategoryPriority,
  getCategoriesWithPriority,
} from '../../../../store/actions/settings.actions';
import { api } from '../../../../api/api';
import { ICategoryPriority } from '../../../../interfaces/ISettings';
import styles from './CategoriesPrioritySettings.module.scss';

const CategoriesPrioritySettings: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoriesWithPriority());
  }, [dispatch]);

  const categoriesWithPriority = useSelector((state: RootState) =>
    state.settings.categories.filter((category) => category.priority).sort((a, b) => a.priority - b.priority)
  );
  const existingPriorities = new Set<number>(categoriesWithPriority.map((category) => category.priority));

  const [open, setOpen] = React.useState(false);
  const [categoryName, setCategoryName] = useState<string>('');
  const [findedCategories, setFindedCategories] = useState<ICategoryPriority[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategoryPriority | null>(null);

  const clearForm = () => {
    setCategoryName('');
    setSelectedCategory(null);
    setFindedCategories([]);
  };

  useEffect(() => {
    (async () => {
      if (categoryName) {
        setLoading(true);
        const { data } = await api.treeCategories.getByName(categoryName);

        const formattedData = data.map((category) => ({
          name: category.name,
          nameWithParent: category.nameWithParent,
          fullNameWithParents: category.fullNameWithParents,
          key: category.key,
          priority: category.priority,
        }));

        setFindedCategories(formattedData);
        setTimeout(() => setLoading(false), 500);
      }
    })();
  }, [categoryName]);

  useEffect(() => {
    if (!categoryName) {
      setFindedCategories([]);
      setOpen(false);
    }
  }, [categoryName]);

  const handleDelete = (item: ICategoryPriority) => {
    dispatch(changeCategoryPriority({ ...item, priority: 0 }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.categories}>
        {categoriesWithPriority.map((category) => (
          <Tooltip key={category.priority} title={category.fullNameWithParents} placement="top">
            <Chip
              className={styles.item}
              label={`${category.nameWithParent || category.name} - ${category.priority}`}
              variant="outlined"
              onDelete={() => handleDelete(category)}
            />
          </Tooltip>
        ))}
      </div>
      <div className={styles.selector}>
        <Autocomplete
          id="fetch-categories"
          open={open}
          onOpen={() => {
            categoryName && setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={(option) => option.nameWithParent || option.name}
          renderOption={(option) => (
            <Tooltip title={option.fullNameWithParents || ''}>
              <div>{option.nameWithParent || option.name}</div>
            </Tooltip>
          )}
          options={findedCategories}
          loading={loading}
          value={selectedCategory}
          onChange={(event: any, newValue: any) => {
            setSelectedCategory(newValue);
            setCategoryName('');
            setOpen(false);
          }}
          inputValue={categoryName}
          onInputChange={(event, newInputValue) => setCategoryName(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Назва категорії - пошук чутливий до регістру"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>

      <div className={styles.form}>
        {selectedCategory && (
          <CategoriesPriority
            category={selectedCategory}
            existingPriorities={existingPriorities}
            cleanSelector={clearForm}
            dispatch={dispatch}
          />
        )}
      </div>
    </div>
  );
};

export default CategoriesPrioritySettings;
