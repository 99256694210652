import React, { FC, useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import SmsIcon from '@material-ui/icons/Sms';
import DescriptionIcon from '@material-ui/icons/Description';
import styles from './OrdersTable.module.scss';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import GoBackBtn from '../../GoBackBtn/GoBackBtn';
import { ICurrentOrder } from '../../../interfaces/IOrders';
import { COLORS } from '../../../values/colors';
import { AppDispatch } from '../../../store/store';
import { updateOrderParcelNumber } from '../../../store/actions/orders.actions';

interface OrdersItemTableHeaderProps {
  order: ICurrentOrder;
  darkMode: boolean;
}

const OrdersItemTableHeader: FC<OrdersItemTableHeaderProps> = ({ order, darkMode }) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const totalAmount = order.productToOrder.map((item) => item.amount).reduce((a, b) => a + b, 0);

  const parcelNumberArray = order?.productToOrder.reduce((acc: string[], { parcelNumber }) => {
    if (parcelNumber && parcelNumber !== '0' && !acc.includes(parcelNumber)) {
      acc.push(parcelNumber);
    }
    return acc;
  }, []);

  const preparedForRenderParcelNumber =
    parcelNumberArray.length !== 0 ? parcelNumberArray.join(' ; ') : 'Не встановлено';

  const handleChangeParcelNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    const inputValueToNumber = Number(targetValue);
    if (
      Number.isNaN(inputValueToNumber) ||
      !Number.isInteger(inputValueToNumber) ||
      inputValueToNumber < 0 ||
      targetValue.includes('.')
    ) {
      setError('Введіть ціле число');
      return;
    }

    setError('');
    setValue(targetValue.trim());
  };

  const onSubmitParcelNumber = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!value.trim()) {
      return;
    }

    order.productToOrder.forEach((el) => {
      dispatch(updateOrderParcelNumber(order.id, el.id, { parcelNumber: value.split(' ').join('') }));
    });
    setValue('');
  };

  return (
    <div className={styles.orderHeader}>
      <GoBackBtn handleGoBack={() => history.push('/orders')} />
      <div className={styles.headerTitle}>Замовлення № {order.id}</div>
      <div className={styles.headerUserData}>
        <PersonPinIcon style={darkMode ? { color: COLORS.darkBlue } : { color: COLORS.primaryBlue }} />
        {order.additionalFirstName ? order.additionalFirstName : order.user.firstName}&nbsp;
        {order.additionalLastName ? order.additionalLastName : order.user.lastName}
      </div>
      <div className={styles.headerUserData}>
        <a href={`mailto:${order.additionalEmail ? order.additionalEmail : order.user.email}`}>
          <EmailIcon style={darkMode ? { color: COLORS.darkBlue } : { color: COLORS.primaryBlue }} />
          {order.additionalEmail ? order.additionalEmail : order.user.email}
        </a>
      </div>
      <div className={styles.headerUserData}>
        <a
          href={`tel:${
            order.additionalNumber
              ? order.additionalNumber.replace(/ /g, '')
              : order.user.phoneNumber.replace(/ /g, '')
          }`}
        >
          <PhoneInTalkIcon style={darkMode ? { color: COLORS.darkBlue } : { color: COLORS.primaryBlue }} />
          {order.additionalNumber ? order.additionalNumber : order.user.phoneNumber}
        </a>
      </div>
      {order.notcall ? <div className={styles.notcall}> Не Передзвонювати</div> : null}
      {order.comment && (
        <div className={styles.commentBlock}>
          <SmsIcon style={darkMode ? { color: COLORS.darkBlue } : { color: COLORS.primaryBlue }} />
          <b>Коментар до замовлення: </b>
          <span>{order.comment}</span>
        </div>
      )}

      <div>
        <DescriptionIcon style={darkMode ? { color: COLORS.darkBlue } : { color: COLORS.primaryBlue }} />
        <span className={styles.headerUserData}>
          <b>Номер ТТН:</b> {preparedForRenderParcelNumber}
        </span>
        <p className={styles.remark}>
          Ви можете ввести номер ТТН, який буде для усіх товарів даного замовлення
        </p>
        <p className={styles.remark}>* Змінити номер ТТН для кожного товару окремо можна у таблиці</p>
        <form onSubmit={onSubmitParcelNumber} className={styles.formParcelNumber}>
          <input
            type="text"
            value={value}
            onChange={handleChangeParcelNumber}
            style={{ width: '220px' }}
            placeholder={'ввести номер ТТН'}
            title="Введіть ціле цисло"
          />
          <button disabled={!Number.isInteger(Number(value))}>Гаразд</button>
        </form>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>

      <div className={styles.amount}>Загальна сума: {totalAmount}</div>
    </div>
  );
};

export default OrdersItemTableHeader;
