import React, {Dispatch, forwardRef, SetStateAction, useContext, useState} from 'react';
import { Button, makeStyles } from '@material-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import './costomPickerStyle.scss';

import { AppDispatch } from '../../store/store';

import {getOrderByRangeRequest, getOrderStatusByRangeRequest} from '../../store/actions/orders.actions';
import { getCommentsByRangeRequest } from '../../store/actions/comments.actions';
import { getUsersDateRangeRequest } from '../../store/actions/users.actions';
import { COLORS } from '../../values/colors';

import uk from 'date-fns/locale/uk';
import { StatisticActionContext } from '../../context/staistic-action-context';
import { LabelEnum } from './labelEnum';
registerLocale('uk', uk);

enum datePosition {
  start = 'start',
  stop = 'stop',
}

enum btnTarget {
  status = 'status',
  order = 'order',
  users = 'users',
  comments = 'comments',
}

const useStyles = makeStyles({
  dateSections: {
    display: 'block',
    width: '100%',
    marginBottom: '50px',
  },
  datePickers: {
    display: 'flex',
    width: 'fit-content',
    margin: '30px auto 0 auto',
  },
  dateSection: {
    marginRight: '15px',
  },
  buttonsSection: {
    width: '100%',
  },
  buttonsList: {
    listStyle: 'none',
    display: 'flex',
    width: 'fit-content',
    margin: '30px auto 0 auto',
    padding: '0',
  },
  buttonItem: {
    marginRight: '15px',
  },
  button: {
    'backgroundColor': COLORS.primaryBlue,
    'borderRadius': '30px',
    '&:hover': {
      backgroundColor: COLORS.secondaryBlue,
    },
  },
  ordersMenu:{
    position:'relative'
  },
  ordersButtonOne :{
    marginTop:'15px',
    animation: '$AppearanceOne 1s',
    position:'absolute',
    transform: "translate(-50% , 0%)"
  },
  '@keyframes AppearanceOne':{
    '0%':{
      opacity: 0,
      transform: "translate(10% , -130%)"
    },
    '100%':{
      opacity: 1,
      transform: "translate(-50% , 0%)"

    }
  },
  ordersButtonTwo :{
    marginTop:'15px',
    marginLeft:'25px',
    animation: '$AppearanceTwo 1s',
    position:'absolute',
    transform: "translate(50% , 0%)"

  },

  '@keyframes AppearanceTwo':{
    '0%':{
    opacity: 0,
      transform: "translate(-10% , -130%)"
    },
    '100%':{
      opacity: 1,
      transform: "translate(50%, 0%)"

    }
  },

  orders: {
    textAlign:'center',
    width:'150px',
    cursor:'pointer',
    'color':'white',
    padding:'8px 15px 8px 15px',
    marginRight: '15px',
    fontWeight:500,
    textTransform:'uppercase',
    'backgroundColor': COLORS.primaryBlue,
    'borderRadius': '30px',
    'boxShadow':
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      'backgroundColor': COLORS.secondaryBlue,
    },
  },
  selectSection: {
    'outline': `1px solid ${COLORS.primaryBlue}`,
    'backgroundColor': COLORS.primaryBlue,
    'border': 'none',
    'borderRadius': '30px',
    'fontSize': '0.875rem',
    'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif',
    'fontWeight': 500,
    'lineHeight': '1.75',
    'letterSpacing': '0.02857em',
    'padding': '6px 16px',
    'color': COLORS.primaryLight,
    'boxShadow':
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: COLORS.secondaryBlue,
    },
  },
});

interface IDataChart {
  date: string;
  value: string;
  valueSecond?: string;
}
interface IStatisButtons {
  chartData: IDataChart[] | null
  setChartData: Dispatch<SetStateAction<IDataChart[] | null>>
}

const StatisticsButtons: React.FC<IStatisButtons> = ({chartData , setChartData}) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [isOrdersMenuOpen, setIsOrdersMenuOpen] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const dispatch: AppDispatch = useDispatch();

  const { setAction } = useContext(StatisticActionContext);

  const styles = useStyles();

  const onDateChangeHandler = (date: any, position: string) => {
    position === datePosition.start ? setStartDate(date) : setEndDate(date);
  };

  const onClickHandle = (target: string) => {
    let afterEndDate = new Date(endDate);
    afterEndDate.setDate(afterEndDate.getDate() + 1);

    const formatedStartDate = startDate!.toISOString().split('T')[0];
    const formatedEndDate = afterEndDate!.toISOString().split('T')[0];

    switch (target) {
      case btnTarget.order:
        setChartData(null)
        setAction(LabelEnum.orders);
        return dispatch(getOrderByRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.status:
        setChartData(null)
        setAction(LabelEnum.status);
        return dispatch(getOrderStatusByRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.users:
        setChartData(null)
        setAction(LabelEnum.user);
        return dispatch(getUsersDateRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.comments:
        setChartData(null)
        setAction(LabelEnum.comments);
        return dispatch(getCommentsByRangeRequest([formatedStartDate, formatedEndDate]));
    }
  };

  const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: any; onClick: any }>(
    ({ value, onClick }, ref) => (
      <button className={styles.selectSection} onClick={onClick} ref={ref}>
        {value}
      </button>
    )
  );

  return (
    <div className={styles.dateSections}>
      <div className={styles.datePickers}>
        <DatePicker
          locale="uk"
          selected={startDate}
          onChange={(date) => onDateChangeHandler(date, datePosition.start)}
          customInput={<ExampleCustomInput value={undefined} onClick={undefined} />}
          dateFormat="dd.MM.yyyy"
          maxDate={new Date() || endDate}
        />
        <DatePicker
          locale="uk"
          selected={endDate}
          onChange={(date) => onDateChangeHandler(date, datePosition.stop)}
          customInput={<ExampleCustomInput value={undefined} onClick={undefined} />}
          dateFormat="dd.MM.yyyy"
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>
      <div className={styles.buttonsSection}>
        <ul className={styles.buttonsList}>
          <div>
            <li className={styles.orders} onClick={()=>{setIsOrdersMenuOpen(!isOrdersMenuOpen)}}>
              Замовлення
            </li>
            {isOrdersMenuOpen && (
              <div>
                <div className={styles.ordersButtonOne}>
                  <Button
                      className={styles.button}
                      id="order"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => onClickHandle(btnTarget.status)}
                  >
                    Статус замовлення
                  </Button>
                </div>
                <div className={styles.ordersButtonTwo}>
                  <Button
                      className={styles.button}
                      id="order"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => onClickHandle(btnTarget.order)}
                  >
                    Статус оплати
                  </Button>
                </div>
              </div>)
            }
          </div>
          <li className={styles.buttonItem}>
            <Button
              className={styles.button}
              id="users"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => onClickHandle(btnTarget.users)}
            >
              користувачі
            </Button>
          </li>
          <li>
            <Button
              className={styles.button}
              id="comments"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => onClickHandle(btnTarget.comments)}
            >
              коментарі
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StatisticsButtons;
