import { IActions } from '../../interfaces/actions';
import { IInvoice, IInvoiceDateRange } from '../../interfaces/IInvoice';
import {
  GENERATE_INVOICE_REQUEST,
  GENERATE_INVOICE_SUCCESS,
  GET_INVOICES_LIST_ERROR,
  GET_INVOICES_LIST_REQUEST,
  GET_INVOICES_LIST_SUCCESS,
  REMOVE_INVOICE_ERROR,
  REMOVE_INVOICE_REQUEST,
  REMOVE_INVOICE_SUCCESS,
} from '../types';

export const getInvoicesListRequest = (): IActions => ({ type: GET_INVOICES_LIST_REQUEST });

export const getInvoicesListSuccess = (invoicesList: IInvoice[]): IActions => ({
  type: GET_INVOICES_LIST_SUCCESS,
  data: invoicesList,
});

export const getInvoicesListError = (message: string): IActions => ({
  type: GET_INVOICES_LIST_ERROR,
  data: message,
});

export const removeInvoiceRequest = (invoiceName: string): IActions => ({
  type: REMOVE_INVOICE_REQUEST,
  data: { invoiceName },
});

export const removeInvoiceSuccess = (invoiceName: string): IActions => ({
  type: REMOVE_INVOICE_SUCCESS,
  data: { invoiceName },
});

export const removeInvoiceError = (message: string): IActions => ({
  type: REMOVE_INVOICE_ERROR,
  data: message,
});

export const generateInvoiceRequest = (invoiceDateRange: IInvoiceDateRange): IActions => ({
  type: GENERATE_INVOICE_REQUEST,
  data: { invoiceDateRange },
});

export const generateInvoiceSuccess = (invoiceDateRange: IInvoiceDateRange): IActions => ({
  type: GENERATE_INVOICE_SUCCESS,
  data: { invoiceDateRange },
});

export const generateInvoiceError = (message: string): IActions => ({
  type: GET_INVOICES_LIST_ERROR,
  data: message,
});
