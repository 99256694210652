import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { api } from '../../../../api/api';

import { AppDispatch } from '../../../../store/store';
import { ITreeCategory } from '../../../../interfaces/ITreeCategory';
import { updateTreeCategoryRequest } from '../../../../store/actions/treeCategories.actions';

import { Button, createStyles, makeStyles, TextField, ThemeOptions } from '@material-ui/core';
import { failSnackBar } from '../../../../store/actions/snackbar.actions';
import styles from './TreeCategoryEditModalForm.module.scss';
import { COLORS } from '../../../../values/colors';
import IconUpload from './IconUpload';
import { treeCategoryValidationShema } from '../../../../pages/TreeCategories/TreeCategoryInfo/treeCategoryValidationShema';

interface FormDialogProps {
  category: ITreeCategory;
  closeModal: () => void;
}

const useStyles = makeStyles(
  (): ThemeOptions =>
    createStyles({
      saveButton: {
        'borderRadius': '30px',
        'color': COLORS.primaryLight,
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      closeButton: {
        'borderRadius': '30px',
        'color': COLORS.primaryLight,
        'backgroundColor': COLORS.primaryGray,
        '&:hover': {
          backgroundColor: COLORS.secondaryGray,
        },
      },
      input: {
        'width': '396px',
        'marginBottom': '10px',
        '& label.Mui-focused': {
          color: COLORS.frenchPlum,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: COLORS.frenchPlum,
        },
      },
    })
);

export const categoryValidation = async (dispatch: Dispatch, parentId?: number) => {
  if (parentId) {
    const { data: parentCategory } = await api.treeCategories.getById(parentId);

    if (parentCategory?.characteristicGroup?.length) {
      dispatch(
        failSnackBar(
          'Неможливо змінити надкатегорію. Надкатегорія містить характеристики, тому не може мати підкатегорії.'
        )
      );
      return false;
    }
  }

  return true;
};

const TreeCategoryEditModalForm: React.FC<FormDialogProps> = ({ category, closeModal }) => {
  const classes = useStyles();
  const [formError, setFormError] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const initialValues = {
    name: category?.name ? category.name : '',
    key: category?.key ? category.key : '',
    description: category?.description ? category.description : '',
    parent: category?.parent?.id ? category.parent.id : null,
    icon: category?.icon?.name ? category.icon.name : '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: treeCategoryValidationShema,
    onSubmit: async (_values, { setSubmitting }) => {
      const validation = await categoryValidation(
        dispatch,
        _values.parent ? _values.parent : category?.parent?.id
      );

      if (!validation) {
        return false;
      }

      const formData = new FormData();
      if (typeof _values.icon !== 'string') {
        formData.append('icon', _values.icon);
      }
      formData.append('id', '' + category?.id);
      if (_values.name !== initialValues.name) formData.append('name', _values.name);

      formData.append('key', _values.key);

      if (_values.description !== initialValues.description)
        formData.append('description', _values.description);

      if (_values.parent !== initialValues.parent) formData.append('parentCategory', '' + _values.parent);

      dispatch(updateTreeCategoryRequest(formData));
      setSubmitting(true);
      closeModal();
    },
  });

  useEffect(() => {
    setFormError(false);

    if (formik.errors.icon) {
      dispatch(failSnackBar(formik.errors.icon));
      setFormError(true);
    }
  }, [formik.errors, dispatch]);

  const dragOverHandler = (event: React.DragEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const dropHandler = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      let file = event.dataTransfer.items[0].getAsFile() as File;
      const availableFormats = ['svg+xml'];
      const imageFormat = file?.type.replace(/image[/]/, '');

      setFormError(false);

      if (file?.size > 10000000) {
        dispatch(failSnackBar('Занадто великий файл'));
        setFormError(true);
      }
      if (!availableFormats.includes(imageFormat)) {
        dispatch(failSnackBar('Формат не підтримується'));
        setFormError(true);
      }

      const domNode = event.target as HTMLElement;
      const labelElement = domNode.closest('label') as HTMLElement;
      const inputElement = labelElement.querySelector('input') as HTMLInputElement;
      if (inputElement != null) {
        formik.setFieldValue(inputElement.name, file);
      }
    }
  };

  return (
    <form
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
      onSubmit={formik.handleSubmit}
      onClick={(e) => e.stopPropagation()}
      className={styles.formContainer}
    >
      <div className={styles.row}>
        <TextField
          className={styles.input + ' ' + classes.input}
          value={formik.values.name}
          type="text"
          name="name"
          label="Ім&#39;я категорії"
          id="name-field"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </div>
      <div className={styles.row}>
        <TextField
          className={styles.input + ' ' + classes.input}
          value={formik.values.key}
          type="text"
          name="key"
          id="key-field"
          label="Ключ"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.key && Boolean(formik.errors.key)}
          helperText={formik.touched.key && formik.errors.key}
        />
      </div>
      <div className={styles.row}>
        <TextField
          className={styles.input + ' ' + classes.input}
          value={formik.values.description}
          type="text"
          name="description"
          id="description-field"
          label="Опис"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
      </div>
      {category?.parent?.id ? (
        <div className={styles.row}>
          <TextField
            className={styles.input + ' ' + classes.input}
            value={formik.values.parent}
            type="number"
            name="parent"
            id="parent-field"
            label="ID надкатегорії"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.parent && Boolean(formik.errors.parent)}
            helperText={formik.touched.parent && formik.errors.parent}
          />
        </div>
      ) : null}
      <div className={styles.row}>
        <IconUpload
          field={formik.values.icon}
          fieldId={'iconId'}
          caption={'*Розмір фото не має перевищувати 10 МБ *Підтримувані формати фото: .svg'}
          setFieldValue={formik.setFieldValue}
          errors={formik.errors.icon}
        />
      </div>
      <div className={styles.buttonsRow}>
        <Button
          className={styles.submit + ' ' + classes.saveButton}
          type="submit"
          disabled={formik.isSubmitting || formError}
        >
          Зберегти
        </Button>
        <Button className={classes.closeButton} onClick={closeModal}>
          Закрити
        </Button>
      </div>
    </form>
  );
};

export default TreeCategoryEditModalForm;
