import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import StatisticsButtons from './StatisticsButton';
import StatisticsBlock from './StatisticsBlock';
import { StatisticActionContext } from '../../context/staistic-action-context';

const useStyles = makeStyles({
  statisticsSection: {
    display: 'block',
    width: '100%',
    marginTop: '50px',
  },
});

interface IDataChart {
  date: string;
  value: string;
  valueSecond?: string;
}

const StatisticsSection: React.FC = () => {
  const styles = useStyles();
  const [action, setAction] = useState(null);
  const [chartData, setChartData] = useState<IDataChart[] | null>(null);


  return (
    <StatisticActionContext.Provider value={{ action, setAction }}>
      <div className={styles.statisticsSection}>
        <StatisticsButtons  chartData ={chartData} setChartData={setChartData}/>
        <StatisticsBlock chartData ={chartData} setChartData={setChartData}/>
      </div>
    </StatisticActionContext.Provider>
  );
};

export default StatisticsSection;
