import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesListRequest } from '../store/actions/invoice.actions';
import { AppDispatch } from '../store/store';
import { createSelector } from 'reselect';

const selectInvoiceItems = (state) => state.invoice.invoicesList;

const selectInvoices = createSelector(selectInvoiceItems, (invoices) => invoices.map((i) => i));

const useInvoices = () => {
  const dispatch: AppDispatch = useDispatch();

  const invoicesList: any = useSelector(selectInvoices);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getInvoicesListRequest());
    };

    fetchData();
  }, [dispatch]);

  return { invoicesList, dispatch };
};

export default useInvoices;
