import { LinearProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColumnsBtn from '../../components/ColumnsBtn/ColumnsBtn';
import ColumnsMenu from '../../components/ColumnsMenu/ColumnsMenu';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import FeedbacksTable from '../../components/Tables/Feedbacks/FeedbacksTable';
import { deleteFeedbackRequest, getFeedbacksRequest } from '../../store/actions/feedbacks.actions';
import { AppDispatch, RootState } from '../../store/store';
import styles from './FeedbacksPage.module.scss';

export enum cols {
  id = 'ID',
  authorIP = 'IP-адреса',
  text = 'Відгук',
  author = 'Автор',
  createdAt = 'Створено',
  updatedAt = 'Оновлено',
}

export default function FeedbacksPage() {
  const dispatch: AppDispatch = useDispatch();
  const { list, loading, paginationPage, paginationLimit, sort, sortDirect, count } = useSelector(
    (state: RootState) => state.feedbacks
  );
  const [showColumnsMenu, setShowColumnsMenu] = useState<boolean>(false);
  const [activeColumns, setActiveColumns] = useState<string[]>([
    cols.id,
    cols.authorIP,
    cols.text,
    cols.author,
    cols.createdAt,
    cols.updatedAt,
  ]);

  const handleColumns = (column: string) =>
    activeColumns.includes(column)
      ? setActiveColumns(activeColumns.filter((col) => col !== column))
      : setActiveColumns([...activeColumns, column]);

  const [openDeleteFeedbackDialog, setOpenDeleteFeedbackDialog] = useState<boolean>(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState<number>(0);

  const handleDeleteFeedback = () => {
    const newPage =
      paginationPage > 1 && count - (paginationPage - 1) * paginationLimit === 1
        ? paginationPage - 1
        : paginationPage;
    feedbackToDelete &&
      dispatch(deleteFeedbackRequest(feedbackToDelete, newPage, paginationLimit, sort, sortDirect));
    setOpenDeleteFeedbackDialog(false);
  };

  return (
    <>
      {loading && <LinearProgress />}

      {openDeleteFeedbackDialog && (
        <CustomConfirm
          openDeleteDialog={openDeleteFeedbackDialog}
          closeDeleteDialog={() => setOpenDeleteFeedbackDialog(false)}
          name="відгук"
          warning="Ця операція є незворотньою. Дані буде неможливо відновити"
          handleDelete={handleDeleteFeedback}
        />
      )}

      <div className={styles.container}>
        {showColumnsMenu && (
          <ColumnsMenu
            allColumns={cols}
            activeColumns={activeColumns}
            showColumnsMenu={showColumnsMenu}
            setShowColumnsMenu={setShowColumnsMenu}
            handleColumns={handleColumns}
          />
        )}

        <div className={styles['header-btn-wrapper']}>
          <ColumnsBtn handleClick={() => setShowColumnsMenu(true)} />
        </div>
        <div className={styles['table-wrapper']}>
          {list && (
            <FeedbacksTable
              activeColumns={activeColumns}
              setOpenDeleteFeedbackDialog={setOpenDeleteFeedbackDialog}
              setFeedbackToDelete={setFeedbackToDelete}
            />
          )}
        </div>
      </div>
    </>
  );
}
