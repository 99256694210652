import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Button, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { changeParser, getParsersStatusesRequest } from '../../store/actions/parsers.actions';
import { RootState } from '../../store/store';
import style from './ParsersPage.module.scss';
import Preloader from '../../components/Preloader/Preloader';
import { IParserStatus } from '../../interfaces/IParsers';
import { ConsoleParserPage } from './ParserPages/ConsoleParserPage';
import { stylesParserPage } from './styles';
import useModal from '../../hooks/useModal';
import ProductsSizesModal from '../../components/Modals/ProductsSizesModal/ProductsSizesModal';
import ParserStatus from './ParserStatus/ParserStatus';

const useStyles = makeStyles(stylesParserPage);

const ParsersPage: React.FC = () => {
  const dispatch = useDispatch();
  const { statuses, isLoading, activeParser } = useSelector((state: RootState) => state.parsers);
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const classes = useStyles();
  const { isOpened, handleClickOpen, handleClose } = useModal();

  useEffect(() => {
    dispatch(getParsersStatusesRequest(activeParser));
  }, [dispatch, activeParser]);

  const handleChange = (event: any, newValue: string) => {
    dispatch(changeParser(newValue));
  };

  return (
    <>
      {isOpened && <ProductsSizesModal isModalOpen={isOpened} closeModal={handleClose} />}
      <div className={style.tabs}>
        <Tabs
          value={activeParser}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab value="fashionGirl" label="FASHION GIRLS" />
          <Tab value="pobedov" label="POBEDOV" />
          <Tab value="letsShop" label="LETSSHOP" />
          <Tab value="whiteMandarin" label="WHITE MANDARIN" />
          <Tab value="sewel" label="SEWEL" />
        </Tabs>
        <Button
          variant="contained"
          type="button"
          onClick={handleClickOpen}
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
        >
          Розміри продукції
        </Button>
      </div>
      <div className={style.container}>
        {!isLoading ? (
          <Preloader />
        ) : (
          <>
            <div className={style.title}>Сторінка парсера {activeParser.toUpperCase()}</div>
            <ParserStatus />
            {<ConsoleParserPage statuses={statuses} />}
          </>
        )}
      </div>
    </>
  );
};

export default ParsersPage;

export interface IParserLog {
  parser: string;
  data: string;
  newStatus?: IParserStatus | undefined;
}
