import React from 'react';
import { Form, FormikProps } from 'formik';
import { makeStyles, ThemeOptions } from '@material-ui/core/styles';
import { alpha, Button, createStyles, DialogActions, Switch, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { IFormAutoStartParsersValues } from '../../../../interfaces/widget-form';
import { RootState } from '../../../../store/store';
import { COLORS } from '../../../../values/colors';


const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      form: {
        width: '100%',
      },
      inputContainer: {
        display: 'grid',
        gridTemplateColumns: '300px 100px',
        alignItems: 'center',
        marginBottom: '20px',
      },
      title: {
        width: '210px',
        marginRight: '10px',
      },
      input: {
        width: '150px',
      },
      secondaryHeading: {
        flexGrow: 1,
        marginLeft: '30px',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      errorMessage: {
        color: 'red',
      },
      btn: {
        padding: '6px 15px 6px 15px',
        borderRadius: '30px',
        color: COLORS.primaryLight,
      },
      btnLight: {
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      btnDark: {
        'backgroundColor': COLORS.darkGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGreen,
        },
      },
      switch: {
        '& .MuiSwitch-switchBase.Mui-checked': {
          'color': COLORS.primaryGreen,
          '&:hover': {
            backgroundColor: alpha(COLORS.primaryGreen, theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: COLORS.primaryGreen,
        },
        'marginLeft': 'auto',
      },
      switchDark: {
        '& .MuiSwitch-switchBase.Mui-checked': {
          'color': COLORS.darkGreen,
          '&:hover': {
            backgroundColor: alpha(COLORS.darkGreen, theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: COLORS.darkGreen,
        },
        'margin': 'left',
      },
    })
);

const InnerForm: React.FC<FormikProps<IFormAutoStartParsersValues>> = (props) => {
  const classes = useStyles();

  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [state, setState] = React.useState({
    feed: props.values.feed,
    letsShop: props.values.letsShop,
    whiteMandarin: props.values.whiteMandarin,
    pobedov: props.values.pobedov,
    fashionGirl: props.values.fashionGirl,
    sewel: props.values.sewel,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    props.setFieldValue(event.target.name, event.target.checked);
  };

  return (
    <Form className={classes.form}>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>Feed</Typography>
        <Switch
          className={darkMode ? classes.switchDark : classes.switch}
          checked={state.feed}
          onChange={handleChange}
          name="feed"
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>Lets shop</Typography>
        <Switch
          className={darkMode ? classes.switchDark : classes.switch}
          checked={state.letsShop}
          onChange={handleChange}
          name="letsShop"
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>White mandarin</Typography>
        <Switch
          className={darkMode ? classes.switchDark : classes.switch}
          checked={state.whiteMandarin}
          onChange={handleChange}
          name="whiteMandarin"
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>Fashion girl</Typography>
        <Switch
          className={darkMode ? classes.switchDark : classes.switch}
          checked={state.fashionGirl}
          onChange={handleChange}
          name="fashionGirl"
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>Pobedov</Typography>
        <Switch
          className={darkMode ? classes.switchDark : classes.switch}
          checked={state.pobedov}
          onChange={handleChange}
          name="pobedov"
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>Sewel</Typography>
        <Switch
          className={darkMode ? classes.switchDark : classes.switch}
          checked={state.sewel}
          onChange={handleChange}
          name="sewel"
        />
      </div>
      <DialogActions>
        <Button
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          variant="contained"
          type="submit"
          disabled={!props.isValid}
        >
          Зберегти
        </Button>
      </DialogActions>
    </Form>
  );
};

export default InnerForm;
