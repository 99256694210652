import 'date-fns';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { IGenerateInvoiceModal } from '../../../interfaces/modals';
import { alpha, Box, Button, createStyles, makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import 'moment/locale/uk';
import { COLORS } from '../../../values/colors';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import classnames from 'classnames';
import { generateInvoiceRequest } from '../../../store/actions/invoice.actions';
import { DefinedRange, DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { inputDateRanges, staticDateRanges } from '../../../utils/dateRanges';
import { IDateRange } from '../../../interfaces/IDate';
import ReactDatePicker from 'react-datepicker';

interface GenerateInvoiceProps {
  dispatch: AppDispatch;
  modalData: IGenerateInvoiceModal;
  setDoingAction: React.Dispatch<React.SetStateAction<boolean>>;
  isDoingAction: boolean;
}

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      radio: {
        '&$checked': {
          color: COLORS.primaryGreen,
        },
        '&:hover': {
          backgroundColor: COLORS.secondaryOttoman,
        },
      },
      radioDark: {
        '&$checked': {
          color: COLORS.darkGreen,
        },
        '&:hover': {
          backgroundColor: alpha(COLORS.darkGreen, theme.palette.action.hoverOpacity),
        },
      },
      checked: {},
      btnWrapper: {
        margin: '10px',
      },
      btn: {
        marginLeft: '15px',
        borderRadius: '30px',
        width: '120px',
        padding: '6px 15px 6px 15px',
        color: COLORS.primaryLight,
      },
      btnConfirmLight: {
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      btnConfirmDark: {
        'backgroundColor': COLORS.secondaryDarkGreen,
        '&:hover': {
          backgroundColor: COLORS.darkGreen,
        },
      },
      btnResetLight: {
        'backgroundColor': COLORS.primaryBlue,
        '&:hover': {
          backgroundColor: COLORS.secondaryBlue,
        },
      },
      btnResetDark: {
        'backgroundColor': COLORS.secondaryDarkBlue,
        '&:hover': {
          backgroundColor: COLORS.darkBlue,
        },
      },
      btnCancelLight: {
        'backgroundColor': COLORS.primaryGray,
        '&:hover': {
          backgroundColor: COLORS.secondaryGray,
        },
      },
      btnCancelDark: {
        'backgroundColor': COLORS.darkGray,
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGray,
        },
      },
      datePicker: {
        'width': '130px',
        'padding': '0.4375rem 0.75rem',
        'border': '0',
        'fontSize': '0.875rem',
        'height': '30px',
        'lineHeight': '1.5',
        'borderRadius': '0.3rem',
        'boxShadow': '1px 1px 5px 0px rgba(221,226,231,1)',
        'textAlign': 'center',
        '&:focus': {
          outline: '2px solid #3d91ff',
        },
      },
      datePickerContainer: {
        backgroundColor: '#eff2f7',
        height: '50px',
        borderRadius: '3px',
      },
      datePickerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    })
);

const FormDialog: React.FC<GenerateInvoiceProps> = ({
  dispatch,
  modalData,
  setDoingAction,
  isDoingAction,
}) => {
  const classes = useStyles();

  const { darkMode } = useSelector((state: RootState) => state.theme);

  const { handleClose, isOpened } = modalData;

  const [dateRange, setDateRange] = useState<IDateRange[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleChangeDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  const reset = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const submit = async () => {
    setDoingAction(true);
    await dispatch(
      generateInvoiceRequest({ startDate: dateRange[0].startDate, endDate: dateRange[0].endDate })
    );
    handleClose();
    reset();
    window.location.reload();
  };

  return (
    <div>
      <Dialog
        open={isOpened}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Вибрати період формування інвойсу</DialogTitle>
        <DialogContent dividers>
          <Grid container justifyContent="center">
            <Grid container direction="row">
              <Grid item style={{ width: '44%' }}>
                <DefinedRange
                  onChange={(item) => handleChangeDateRange([item.selection])}
                  ranges={dateRange}
                  locale={locales.uk}
                  staticRanges={staticDateRanges}
                  inputRanges={inputDateRanges}
                />
              </Grid>
              <Grid item style={{ width: '56%' }}>
                <Grid container direction="row" className={classes.datePickerContainer}>
                  <div
                    style={{
                      width: '54.5%',
                    }}
                    className={classes.datePickerWrapper}
                  >
                    <div>
                      <ReactDatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={dateRange[0].startDate}
                        onChange={(item) =>
                          handleChangeDateRange([
                            { startDate: item, endDate: dateRange[0].endDate, key: dateRange[0].key },
                          ])
                        }
                        onClickOutside={() =>
                          !dateRange[0].startDate
                            ? handleChangeDateRange([
                                {
                                  startDate: new Date(),
                                  endDate: dateRange[0].endDate,
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        onKeyDown={() =>
                          !dateRange[0].startDate
                            ? handleChangeDateRange([
                                {
                                  startDate: new Date(),
                                  endDate: dateRange[0].endDate,
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        maxDate={
                          +dateRange[0].startDate === +dateRange[0].endDate
                            ? new Date()
                            : dateRange[0].endDate
                        }
                        locale={locales.uk}
                        className={classes.datePicker}
                      ></ReactDatePicker>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '45.5%',
                    }}
                    className={classes.datePickerWrapper}
                  >
                    <div>
                      <ReactDatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={dateRange[0].endDate}
                        onChange={(item) =>
                          handleChangeDateRange([
                            { startDate: dateRange[0].startDate, endDate: item, key: dateRange[0].key },
                          ])
                        }
                        onClickOutside={() =>
                          !dateRange[0].endDate
                            ? handleChangeDateRange([
                                {
                                  startDate: dateRange[0].startDate,
                                  endDate: new Date(),
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        onKeyDown={() =>
                          !dateRange[0].endDate
                            ? handleChangeDateRange([
                                {
                                  startDate: dateRange[0].startDate,
                                  endDate: new Date(),
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        maxDate={new Date()}
                        minDate={dateRange[0].startDate}
                        locale={locales.uk}
                        className={classes.datePicker}
                      ></ReactDatePicker>
                    </div>
                  </div>
                </Grid>
                <Grid container justifyContent="center" spacing={0} direction="row">
                  <DateRange
                    onChange={(item) => handleChangeDateRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRange}
                    showDateDisplay={false}
                    locale={locales.uk}
                    maxDate={new Date()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box className={classes.btnWrapper}>
              <Button
                className={classnames(
                  classes.btn,
                  darkMode ? classes.btnConfirmDark : classes.btnConfirmLight
                )}
                variant="contained"
                onClick={submit}
                disabled={isDoingAction}
              >
                Підтвердити
              </Button>
              <Button
                className={classnames(classes.btn, darkMode ? classes.btnResetDark : classes.btnResetLight)}
                variant="contained"
                onClick={reset}
                disabled={isDoingAction}
              >
                Скинути
              </Button>
              <Button
                className={classnames(classes.btn, darkMode ? classes.btnCancelDark : classes.btnCancelLight)}
                variant="contained"
                onClick={handleClose}
                disabled={isDoingAction}
              >
                Скасувати
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormDialog;
