export const COLORS = {
  primaryGreen: '#26bd62',
  secondaryGreen: '#1ba34f',
  primaryLight: '#ffffff',
  fontColor: '#4e5a5e',
  primaryBlue: '#007bff',
  secondaryBlue: '#0069d9',
  primaryGray: '#6c757d',
  secondaryGray: '#5a6268',
  primaryOttoman: '#e9f8ef',
  secondaryOttoman: '#d5f2e1',
  salem: '#168541',
  frenchPlum: '#822a60',
  primaryRed: '#ff0000',
  secondaryRed: '#d90000',
  darkGreen: '#4A7C56',
  secondaryDarkGreen: '#3c6646',
  darkGray: '#323232',
  secondaryDarkGray: '#595959',
  darkBlue: '#0a54a3',
  secondaryDarkBlue: '#08488c',
  darkRed: '#c71010',
  secondaryDarkRed: '#ab0c0c',
  primaryYellow: '#ffc107',
  darkYellow: '#ba9525',
};
