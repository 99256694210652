import { createStyles, makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import InvoicesTable from '../../components/Tables/Invoices/InvoicesTable';
import useInvoices from '../../hooks/useInvoices';
import { IInvoice } from '../../interfaces/IInvoice';
import { RootState } from '../../store/store';

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      tableWrapper: {
        padding: theme.spacing(2),
      },
    })
);

const Invoices = () => {
  const classes = useStyles();
  const { invoicesList, dispatch } = useInvoices();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const filterInvoicesData = (array: any[]) => {
    let filteredArray;
    if (array.length > 0) {
      filteredArray = array.filter((i) => i.invoiceFile !== null);
    } else {
      filteredArray = [];
    }
    return filteredArray;
  };

  const tableData: any[] = filterInvoicesData(invoicesList);

  return (
    <div className={classes.tableWrapper}>
      <InvoicesTable
        data={
          tableData.length > 0
            ? tableData.map((i) => {
                return {
                  id: i.id,
                  createdAt: i.createdAt,
                  updatedAt: i.updatedAt,
                  url: i.url,
                  fileSize: i.fileSize,
                  name: i.invoiceFile.name,
                };
              })
            : []
        }
        dispatch={dispatch}
        darkMode={darkMode}
      />
    </div>
  );
};

export default Invoices;
