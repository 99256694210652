import { AxiosResponse } from 'axios';

import {
  IDeleteUsersResponse,
  IResponseMessage,
  IUsersResponse,
  IUsersStatistic,
} from './../interfaces/IUsers';

import {
  IGetTreeCategoriesResponse,
  ITreeCategory,
  IAddTreeCategory,
  IDisableEnableCategory,
  ICharToAdd,
} from '../interfaces/ITreeCategory';

import {
  IAddCharResponse,
  IAddImgResponse,
  IAddProduct,
  IGetProductById,
  IGetProducts,
  IProductCharRequest,
  IUpdateProduct,
  IUpdateAvailabilityProduct,
  IDisableProduct,
  IDeleteProductChars,
  IProductsFilter,
  IUpdateDiscountedPrice,
  IProductStatistic,
  IMessage,
  IShopList,
} from '../interfaces/IProducts';
import { ISearchItems, ISearchItemsResponse } from '../interfaces/ISearch';
import { IBasicOrder, IStatisticOrders, IStatusOrders } from '../interfaces/IOrders';
import { TreeCategory } from '../pages/TreeCategories/TreeCategoryInfo/treeCategoryReducer';

import { IActions, IActionsImage } from '../interfaces/actions';
import { ICategoryPriority, ISettingsItem } from '../interfaces/ISettings';
import {
  IDeleteSlideResponse,
  ISlideItem,
  ISlidesResponse,
  ISlideUpdateValues,
  ISlideVisibility,
} from '../interfaces/ISlides';
import { ICommentResponse, ICommentsDateRange, IDeleteCommentResponse } from '../interfaces/IComment';
import { IDeleteFeedbackResponse, IFeedbackResponse } from '../interfaces/IFeedback';
import {
  IUserReqAdd,
  IAuthResponse,
  IUserReqUp,
  IUserCreeds,
  IUserItem,
  IUsersData,
  IuserConfirmEmail,
} from '../interfaces/IUsers';
import instance from './axios-interceptors';
import { Status } from '../enums/orderStatus';
import { IRole } from '../interfaces/IRoles';
import { ISliderAnimation, ISliderAnimations } from '../interfaces/ISliderAnimations';
import { IInvoice, IInvoiceDateRange } from '../interfaces/IInvoice';
import { IParserStatus } from '../interfaces/IParsers';

type FetchedDataType<T> = Promise<AxiosResponse<T>>;

type ApiFetchedDataType = {
  treeCategories: {
    get: () => FetchedDataType<IGetTreeCategoriesResponse>;
    getById: (id: number) => FetchedDataType<IGetTreeCategoriesResponse>;
    getByName: (name: string) => FetchedDataType<ITreeCategory[]>;
    add: (category: IAddTreeCategory) => FetchedDataType<ITreeCategory>;
    delete: (id: number) => FetchedDataType<JSON>;
    update: (data: TreeCategory) => FetchedDataType<IGetTreeCategoriesResponse>;
    disableEnable: (data: IDisableEnableCategory) => FetchedDataType<ITreeCategory>;
    addCharToCategory: (data: ICharToAdd) => FetchedDataType<IAddCharResponse>;
    editCharToCategory: (data: ICharToAdd) => FetchedDataType<IAddCharResponse>;
    deleteCharToCategory: (id: number) => FetchedDataType<JSON>;
  };

  categoryImages: {
    add: (data: FormData) => FetchedDataType<any>;
  };

  products: {
    get: (
      page: number,
      limit: number,
      sort: string,
      sortDirect: string,
      filter: IProductsFilter
    ) => FetchedDataType<IGetProducts>;
    getById: (id: number) => FetchedDataType<IGetProductById>;
    add: (product: IAddProduct) => FetchedDataType<IGetProductById>;
    update: (product: IUpdateProduct) => FetchedDataType<IGetProductById>;
    updateDiscountedPrice: (product: IUpdateDiscountedPrice) => FetchedDataType<IGetProductById>;
    updateImg: (data: FormData) => FetchedDataType<IAddImgResponse>;
    updateMainImg: (data: { productId: number; imgName: string }) => FetchedDataType<IGetProductById>;
    deleteImg: (imgName: string) => FetchedDataType<IGetProductById>;
    deleteProduct: (id: number) => FetchedDataType<JSON>;
    addProductCharValues: (data: IProductCharRequest) => FetchedDataType<IAddCharResponse>;
    updateProductCharValues: (data: IProductCharRequest) => FetchedDataType<IAddCharResponse>;
    deleteProductCharValues: (data: IDeleteProductChars) => FetchedDataType<JSON>;
    updateAvailabilityProduct: (data: IUpdateAvailabilityProduct) => FetchedDataType<IAddCharResponse>;
    disableProduct: (data: IDisableProduct) => FetchedDataType<IAddCharResponse>;
    getProductsSizes: () => FetchedDataType<string[]>;
    getProductStatistic: (productId: number) => FetchedDataType<IProductStatistic>;
    getDiscountInfo: () => FetchedDataType<object>;
    getShopList: () => FetchedDataType<IShopList[]>;
  };

  settings: {
    get: () => FetchedDataType<ISettingsItem>;
    getParsers: () => FetchedDataType<ISettingsItem>;
    put: (settings: IActions) => FetchedDataType<ISettingsItem>;
    getCategoriesWithPriority: () => FetchedDataType<ITreeCategory>;
    changeCategoriesPriority: (categoryPriority: ICategoryPriority[]) => FetchedDataType<IMessage>;
    generateFeed: () => FetchedDataType<IMessage>;
  };

  slides: {
    get: (page: number, limit: number, sort: string, sortDirect: string) => FetchedDataType<ISlidesResponse>;
    add: (slide: FormData) => FetchedDataType<ISlideItem>;
    update: (slide: ISlideUpdateValues) => FetchedDataType<ISlideItem>;
    updateVisibility: (slide: ISlideVisibility) => FetchedDataType<ISlideItem>;
    delete: (
      slide: IActionsImage,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteSlideResponse>;
  };

  orders: {
    get: (page: number, limit: number) => FetchedDataType<IBasicOrder>;
    updateStatus: (id: number, status: Status) => FetchedDataType<IBasicOrder>;
    updatePaymentStatus: (id: number, paymentStatus: boolean) => FetchedDataType<IBasicOrder>;
    update: (
      orderId: number,
      productId: number,
      data: { quantity?: number; color?: string; size?: string }
    ) => FetchedDataType<IBasicOrder>;
    getById: (id: number) => FetchedDataType<IBasicOrder>;
    getByParams: (page: number, limit: number, searchValue: string) => FetchedDataType<IBasicOrder>;
    getByDatesRange: (datesArray: string[]) => FetchedDataType<IStatisticOrders[]>;
    getStatusByDatesRange: (datesArray: string[]) => FetchedDataType<IStatusOrders[]>;
    updateProductInOrder: (data) => FetchedDataType<IBasicOrder>;
  };

  comments: {
    get: (page: number, limit: number, sort: string, sortDirect: string) => FetchedDataType<ICommentResponse>;
    getByDatesRange: (datesArray: string[]) => FetchedDataType<ICommentsDateRange[]>;
    delete: (
      id: number,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteCommentResponse>;
  };

  feedbacks: {
    get: (
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IFeedbackResponse>;
    delete: (
      id: number,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteFeedbackResponse>;
  };

  users: {
    get: (page: number, limit: number, sort: string, sortDirect: string) => FetchedDataType<IUsersResponse>;
    getByDatesRange: (datesArray: string[]) => FetchedDataType<IUsersStatistic>;
  };
  user: {
    auth: (user: IUserCreeds) => FetchedDataType<IAuthResponse>;
    get: () => FetchedDataType<IUsersData>;
    add: (user: IUserReqAdd) => FetchedDataType<IUserItem>;
    update: (user: IUserReqUp) => FetchedDataType<IUserItem>;
    delete: (
      id: number,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteUsersResponse>;
    requestPasswordInstall: (data: { email: string }) => FetchedDataType<IResponseMessage>;
    updateUserData: (userData: IUserReqUp) => FetchedDataType<IUserReqUp>;
    deleteAvatar: () => FetchedDataType<IResponseMessage>;
    addAvatar: (data) => FetchedDataType<IResponseMessage>;
    confirmEmail: (data: IuserConfirmEmail) => FetchedDataType<JSON>;
  };
  roles: {
    get: () => FetchedDataType<IRole[]>;
  };
  search: {
    getSearchItems: (fields: ISearchItems) => FetchedDataType<ISearchItemsResponse>;
  };
  sliderAnimations: {
    getSliderAnimations: () => FetchedDataType<ISliderAnimations>;
    getActiveSliderAnimation: () => FetchedDataType<ISliderAnimation>;
    changeActiveSliderAnimation: (id: number, isActive: boolean) => FetchedDataType<ISliderAnimation>;
  };

  invoice: {
    getInvoicesList: () => FetchedDataType<IInvoice[]>;
    removeInvoice: (name: string) => FetchedDataType<JSON>;
    generateInvoice: (invoiceDateRange: IInvoiceDateRange) => FetchedDataType<JSON>;
    getInvoiceFile: (name: string) => FetchedDataType<Blob>;
  };

  parsers: {
    apiGetParsersStatus: () => Promise<AxiosResponse<IParserStatus[]>>;
  };
};

export const api: ApiFetchedDataType = {
  treeCategories: {
    get: () => instance.get('/category/tree'),
    getById: (id) => instance.get(`/category/tree/${id}`),
    getByName: (name) => instance.get(`/category/name?name=${name}`),
    add: (category) => instance.post('/category/tree', category),
    update: (data) => {
      return instance.patch('/category/tree', data);
    },
    disableEnable: (data) => instance.patch('/category/tree/disablecategories', data),
    delete: (id) => instance.delete(`/category/tree/${id}`),
    addCharToCategory: (data) => instance.post('/characteristics', data),
    editCharToCategory: (data) => instance.patch(`/characteristics/${data.id}`, data),
    deleteCharToCategory: (id) => instance.delete(`/characteristics/${id}`),
  },

  categoryImages: {
    add: (data) => instance.post('/category/category-images', data),
  },

  products: {
    get: (page, limit, sort, sortDirect, filter) => {
      let url: string = `/product/admin?page=${page}&limit=${limit}`;
      if (sort) url = url + `&sort=${sort}`;
      if (sortDirect) url = url + `&sortDirect=${sortDirect}`;
      if (filter?.id) url = url + `&filterId=${filter.id}`;
      if (filter?.name) url = url + `&filterName=${filter.name}`;
      if (filter?.category) url = url + `&filterCategory=${filter.category}`;
      if (filter?.price) url = url + `&filterPrice=${filter.price}`;
      if (filter?.shop) url = url + `&filterShop=${filter.shop}`;
      if (filter?.size) url = url + `&filterSize=${filter.size}`;
      return instance.get(url);
    },
    getById: (id) => instance.get(`/product/${id}`),
    add: (product) => instance.post('/product', product),
    updateImg: (data) => instance.post('/product/multipleimages', data),
    addProductCharValues: (data) => instance.post('/characteristics-values', data),
    update: ({ id, ...product }) => instance.patch(`/product/${id}`, product),
    updateDiscountedPrice: ({ productId, ...product }) => instance.patch(`/product/${productId}`, product),
    updateMainImg: (data) => instance.patch('/product/img/preview', data),
    updateProductCharValues: (data) => instance.patch('/characteristics-values', data),
    updateAvailabilityProduct: ({ productId, ...product }) =>
      instance.patch(`/product/${productId}`, product),
    disableProduct: ({ productId, ...product }) => instance.patch(`/product/${productId}`, product),
    deleteProductCharValues: (data) => instance.delete('/characteristics-values', { data }),
    deleteImg: (imgName) => instance.delete(`/product/img/${imgName}`),
    deleteProduct: (id) => instance.delete(`/product/${id}`),
    getProductsSizes: () => instance.get('/characteristics-values/filtered-sizes'),
    getProductStatistic: (productId) => instance.get(`/product-statistic/${productId}`),
    getDiscountInfo: () => instance.get('/product/discountInfo'),
    getShopList: () => instance.get('/shop-parser/shopList'),
  },

  slides: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/slide?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    add: (slide) => instance.post(`/slide`, slide),
    update: (slide) => instance.patch(`/slide/${slide.id}`, slide.body),
    updateVisibility: (slide) => instance.patch(`/slide/visibility/${slide.id}`, { isShown: slide.isShown }),
    delete: (slide, page, limit, sort, sortDirect) =>
      instance.post(`/slide/${slide.id}`, { page, limit, sort, sortDirect }),
  },

  settings: {
    get: () => instance.get('/parameters'),
    getParsers: () => instance.get('/parameters/parser'),
    put: (settings) => instance.put('/parameters', settings),
    getCategoriesWithPriority: () => instance.get('/category/with-priority'),
    changeCategoriesPriority: (categoryPriority) =>
      instance.post('/category/change-priority', categoryPriority),
    generateFeed: () => instance.get('/shop-parser/feed'),
  },

  orders: {
    get: (page, limit) => instance.get(`/orders?page=${page}&limit=${limit}`),
    getById: (id) => instance.get(`/orders/${id}`),
    getByParams: (page, limit, searchValue) =>
      instance.get(`/orders/params?page=${page}&limit=${limit}&searchValue=${searchValue}`),
    getByDatesRange: (datesArray: string[]) =>
      instance.get(`/orders/statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    getStatusByDatesRange: (datesArray: string[]) =>
      instance.get(`/orders/status?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    updateStatus: (id, status) => instance.patch(`/orders/status/${id}`, status),
    updatePaymentStatus: (id, paymentStatus) => instance.patch(`/orders/payment-status/${id}`, paymentStatus),
    update: (orderId, productId, data) => instance.put(`/orders/${orderId}/${productId}`, data),
    updateProductInOrder: (data) => instance.put('/orders/product/', data),
  },

  users: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/users?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    getByDatesRange: (datesArray: string[]) =>
      instance.get(`/users/statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
  },

  user: {
    get: () => instance.get('/users/profile'),
    auth: (user) => instance.post('/auth/admin/login', user),
    add: (user) => instance.post('/auth/register-through-admin', user),
    addAvatar: (data) => instance.post('users/avatar/add', data),
    confirmEmail: (data) => instance.post('users/newEmail/set', data),
    requestPasswordInstall: (email) => instance.post('/users/password/reset', email),
    updateUserData: (userData) => instance.patch('/users/update', userData),
    update: ({ id, ...user }) => instance.put(`/users/${id}`, user),
    delete: (id, page, limit, sort, sortDirect) =>
      instance.post(`/users/${id}`, { page, limit, sort, sortDirect }),
    deleteAvatar: () => instance.delete('users/avatar'),
  },
  comments: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/comments?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    getByDatesRange: (datesArray: string[]) =>
      instance.get(`/comments/statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    delete: (id, page, limit, sort, sortDirect) =>
      instance.post(`/comments/admin/${id}`, { page, limit, sort, sortDirect }),
  },
  feedbacks: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/feedbacks?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    delete: (id, page, limit, sort, sortDirect) =>
      instance.post(`/feedbacks/admin/${id}`, { page, limit, sort, sortDirect }),
  },
  roles: {
    get: () => instance.get('/roles'),
  },
  search: {
    getSearchItems: (fields) =>
      instance.get(
        `/search/admin?${fields.option}=${fields.query}&page=${fields.page}&limit=${fields.limit}`
      ),
  },
  sliderAnimations: {
    getSliderAnimations: () => instance.get('/slider-animations'),
    getActiveSliderAnimation: () => instance.get('/slider-animations/active'),
    changeActiveSliderAnimation: (id: number, isActive: boolean) =>
      instance.patch(`/slider-animations/change-active/${id}/${isActive}`),
  },
  invoice: {
    getInvoicesList: () => instance.get('/invoice/all'),
    getInvoiceFile: (name: string) => instance.get(`/invoice/${name}`, { responseType: 'blob' }),
    generateInvoice: (invoiceDateRange: IInvoiceDateRange) => instance.post('/invoice', invoiceDateRange),
    removeInvoice: (name: string) => instance.delete(`/invoice/${name}`),
  },

  parsers: {
    apiGetParsersStatus: () => instance.get('/shop-parser/status'),
  },
};
