import { RootState } from './../store';
import { put, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IActions } from '../../interfaces/actions';
import {
  apiGetUsers,
  apiAddUser,
  apiUpdateUser,
  apiDeleteUser,
  apiGetUsersByQuery,
  apiGetByRangeUsers,
} from './services/users.service';
import {
  addUserError,
  addUserSuccess,
  deleteUserError,
  deleteUserSuccess,
  getUsersByQueryError,
  getUsersByQuerySuccess,
  getUsersDateRangeSuccess,
  getUsersError,
  getUsersRequest,
  getUsersSuccess,
  updateUserError,
  updateUserSuccess,
} from '../actions/users.actions';
import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';

export function* getUsersWorker({ data: { page, limit, sort, sortDirect } }: IActions): SagaIterator {
  try {
    const user = yield call(apiGetUsers, page, limit, sort, sortDirect);
    yield put(getUsersSuccess(user));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(getUsersError(error.message));
  }
}

export function* getUsersDateRangeWorker({ data: datesArray }: IActions): SagaIterator {
  try {
    const user = yield call(apiGetByRangeUsers, datesArray);
    yield put(getUsersDateRangeSuccess(user));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(getUsersError(error.message));
  }
}

export function* addUserWorker({ data: { userValues } }: IActions): SagaIterator {
  try {
    const { currentPage, paginationLimit, sort, sortDirect } = yield select(
      (state: RootState) => state.users
    );
    yield call(apiAddUser, userValues);
    yield put(successSnackBar());
    yield put(getUsersRequest(currentPage, paginationLimit, sort, sortDirect));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(addUserError(error.message));
  }
}

export function* updateUserWorker({ data: { userValues } }: IActions): SagaIterator {
  try {
    const user = yield call(apiUpdateUser, userValues);
    yield put(updateUserSuccess(user));
    yield put(successSnackBar());
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(updateUserError(error.message));
  }
}

export function* deleteUserWorker({ data: payload }: IActions): SagaIterator {
  try {
    const { data } = yield call(apiDeleteUser, payload);
    yield put(deleteUserSuccess(data));
    yield put(successSnackBar());
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(deleteUserError(error.message));
  }
}

export function* getUsersByQueryWorker({ data: { searchValue, page, limit } }: IActions): SagaIterator {
  try {
    const users = yield call(apiGetUsersByQuery, searchValue, page, limit);
    yield put(getUsersByQuerySuccess(users));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(getUsersByQueryError(error.message));
  }
}
