import React, { useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from '../../../values/colors';
import { updateOrderParcelNumber } from '../../../store/actions/orders.actions';
import { AppDispatch, RootState } from '../../../store/store';
import styles from './OrdersTable.module.scss';
import { checkForUnwantedCharacters } from '../../../utils/checkForUnwantedCharacters';

const OrdersParcelNumber = ({ row, orderId, darkMode }) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(row.parcelNumber);
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.orders.loading);

  useEffect(() => {
    setValue(row.parcelNumber);
  }, [row.parcelNumber]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    setValue(targetValue.trim());
  };

  const onSubmitNumberChanged = (e, orderId, productId) => {
    e.stopPropagation();
    dispatch(updateOrderParcelNumber(orderId, productId, { parcelNumber: value }));
    setEditable(false);
  };

  const onNumberChangedCancel = () => {
    setEditable(false);
    setValue(row.parcelNumber);
  };

  const isEditable = () => {
    if (loading) {
      return;
    }
    setEditable(true);
  };

  let cell = !editable ? (
    <div className={styles.quantity}>
      <div className="cell">{row.parcelNumber ? row.parcelNumber : '0'}</div>
      <EditIcon
        onClick={isEditable}
        style={darkMode ? { color: COLORS.darkBlue } : { color: COLORS.primaryBlue }}
        fontSize="small"
      />
    </div>
  ) : (
    <div className={styles.edit_quantity}>
      <input
        type="number"
        onKeyDown={checkForUnwantedCharacters}
        value={value || ''}
        onChange={handleChange}
      />
      <DoneIcon
        style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }}
        onClick={(e) => onSubmitNumberChanged(e, orderId, row.id)}
      />
      <CancelIcon
        style={darkMode ? { color: COLORS.darkGray } : { color: COLORS.primaryGray }}
        onClick={onNumberChangedCancel}
      />
    </div>
  );

  return cell;
};
export default OrdersParcelNumber;
