import {FormControl, Select, MenuItem} from '@material-ui/core';
import React, {FC, useState} from 'react';
import {IProductToOrder} from "../../../interfaces/IOrders";
import EditIcon from "@material-ui/icons/Edit";
import {COLORS} from "../../../values/colors";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import CancelIcon from "@material-ui/icons/Cancel";
import {failSnackBar} from "../../../store/actions/snackbar.actions";

interface OrdersColorSelector {
    disabled: boolean;
    value: string;
    handleChange: (e: any) => void;
    menuItems: Array<string>;
    order: any;
    row: any;
}

const OrdersColorSelector: FC<OrdersColorSelector> = ({row,value, disabled, handleChange, menuItems, order}) => {
    const loading = useSelector((state: RootState) => state.orders.loading);
    const [editable, setEditable] = useState<boolean>(false)
    let productToOrder = order.productToOrder.filter(el=> el.product.id === row.product.id)[0]
    const dispatch = useDispatch();
    const isEditable = () => {
        if (loading) {
            return;
        }
        setEditable(true);
    };
    const onQuantityChangedCancel = () => {
        setEditable(false);
    };
    if (!menuItems.includes(productToOrder.color) && !editable) {
        if(productToOrder.color !== null) dispatch(failSnackBar('Оберіть будь ласка колір.'))

        return (
            <div style={{display:'flex', alignItems:'center' , gap:'4px'}}>
                <p style={{color: 'red' , margin:0}}>{productToOrder.color}</p>
                {
                    menuItems.length > 0 && (
                        <EditIcon
                            onClick={isEditable}
                            fontSize="small"
                        />
                    )
                }
            </div>)
    }
    if(editable || menuItems.includes(productToOrder.color)){
        return (
            <FormControl disabled={disabled} style={{display:'flex' , alignItems:'center' , width:'100%' , flexDirection:'row'}}>
                <Select value={value} label={value} onChange={handleChange}>
                    {menuItems.map((item, i) => {
                        return (
                            <MenuItem key={i + `${item}`} value={item}>
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
                {
                    !menuItems.includes(productToOrder.color)
                    &&(
                        <CancelIcon
                            style={{width:'20px'}}
                            onClick={onQuantityChangedCancel}
                        />
                    )
                }
            </FormControl>
        );
    }
};

export default OrdersColorSelector;
