import React from 'react';

interface IStatisticActionContext {
  action: 'status' |'user' | 'comments' | 'orders' | null;
  setAction: React.Dispatch<any>;
}

export const StatisticActionContext = React.createContext<IStatisticActionContext>({
  action: null,
  setAction: () => {},
});
