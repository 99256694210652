import { all, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  ADD_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCTS_BY_QUERY_REQUEST,
  GET_PRODUCTS_REQUEST,
  GET_TREE_CATEGORIES_REQUEST,
  ADD_TREE_CATEGORY_REQUEST,
  ADD_CHAR_TO_CATEGORY,
  EDIT_CHAR_TO_CATEGORY,
  DELETE_TREE_CATEGORY,
  UPDATE_TREE_CATEGORY_REQUEST,
  GET_TREE_CATEGORIES_BY_ID_REQUEST,
  REQUEST_SETTINGS,
  REQUEST_UPDATE_SETTINGS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_ORDER_PARCEL_NUMBER,
  UPLOAD_MAIN_IMG_REQUEST,
  REQUEST_SLIDES,
  REQUEST_UPDATE_SLIDES,
  REQUEST_ADD_SLIDES,
  REQUEST_DELETE_SLIDES,
  GET_ORDERS_REQUEST,
  GET_ORDER_BY_ID_REQUEST,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_PAYMENT_STATUS_REQUEST,
  GET_COMMENTS_REQUEST,
  DELETE_COMMENT_REQUEST,
  GET_FEEDBACKS_REQUEST,
  DELETE_FEEDBACK_REQUEST,
  REQUEST_UPDATE_SLIDE_VISIBILITY,
  GET_USERS_REQUEST,
  ADD_USER_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  USER_SIGN_IN_FETCHING,
  USER_SIGN_OUT,
  USER_FETCH_REQUEST,
  UPDATE_AVAILABILITY_PRODUCT_REQUEST,
  GET_ROLES_REQUEST,
  DISABLE_PRODUCT_REQUEST,
  GET_SEARCH_ITEMS_REQUEST,
  GET_SLIDER_ANIMATIONS_REQUEST,
  UPDATE_PRODUCT_IN_ORDER_REQUEST,
  REQUEST_CHANGE_ACTIVE_SLIDER_ANIMATION,
  GET_INVOICES_LIST_REQUEST,
  REMOVE_INVOICE_REQUEST,
  GENERATE_INVOICE_REQUEST,
  DISABLE_ENABLE_CATEGORY_REQUEST,
  GET_USERS_BY_QUERY_REQUEST,
  UPDATE_PROFILE_USER_REQUEST,
  GER_ORDERS_BY_PARAMS_REQUEST,
  GET_ORDERS_BY_RANGE_REQUEST,
  GET_COMMENTS_BY_RANGE_REQUEST,
  GET_USERS_DATE_RANGE_REQUEST,
  DELETE_AVATAR_REQUEST,
  ADD_AVATAR_REQUEST,
  CONFIRM_CHANGE_EMAIL_REQUEST,
  GET_PARSERS_STATUSES,
  GET_PARSER_SETTINGS,
  UPDATE_CATEGORY_IMAGES_REQUEST,
  GET_PRODUCTS_SIZES_REQUEST,
  REQUEST_UPDATE_PARSER_SETTINGS,
  GET_ORDERS_STATUS_BY_RANGE_REQUEST,
  UPDATE_PRODUCT_DISCOUNTED_PRICE_REQUEST,
  DELETE_CHAR_TO_CATEGORY,
  GET_PRODUCT_STATISTIC_REQUEST,
  GET_CATEGORIES_WITH_PRIORITY,
  CHANGE_CATEGORY_PRIORITY,
  GENERATE_FEED,
  GET_DISCOUNT_INFO_REQUEST,
  GET_SHOP_LIST_REQUEST,
} from './types';

import {
  fetchTreeCategoryWorker,
  addTreeCategoryWorker,
  deleteTreeCategoryWorker,
  updateTreeCategoryWorker,
  getTreeCategoriesByIdWorker,
  disableEnableCategoryWorker,
  addCharToCategoryWorker,
  editCharToCategoryWorker,
  deleteCharToCategoryWorker,
} from './sagas/treeCategories.saga';

import { updateCategoryImagesWorker } from './sagas/categoryImages.saga';

import {
  changeCategoriesPriorityWorker,
  fetchSettingsWorker,
  getParserSettingsWorker,
  getCategoriesWithPriorityWorker,
  updateParserSettingsWorker,
  updateSettingsWorker,
  generateFeedWorker,
} from './sagas/settings.saga';
import {
  addProductWorker,
  deleteProductWorker,
  getProductByIdWorker,
  getProductsByQueryWorker,
  getProductsWorker,
  updateProductWorker,
  updateDiscountedPriceWorker,
  uploadMainImgWorker,
  updateAvailabilityProductWorker,
  disableProductWorker,
  getProductsSizesWorker,
  getProductStatisticWorker,
  getDiscountInfoWorker,
  getShopListWorker,
} from './sagas/products.saga';
import {
  addSlideWorker,
  fetchSlideWorker,
  deleteSlideWorker,
  updateSlideVisibilityWorker,
  updateSlideWorker,
} from './sagas/slides.saga';
import {
  getOrdersWorker,
  getOrdersByIdWorker,
  updateOrderWorker,
  updateOrderStatusWorker,
  updateOrderPaymentStatusWorker,
  getOrdersByParamsWorker,
  updateProductInOrderWorker,
  getOrdersByDateRangeWorker,
  updateOrderNumber,
  getOrdersStatusByDateRangeWorker,
} from './sagas/orders.saga';
import { deleteCommentWorker, getCommentsByDateRangeWorker, getCommentsWorker } from './sagas/comments.saga';
import { deleteFeedbackWorker, getFeedbacksWorker } from './sagas/feedbacks.saga';
import {
  addUserWorker,
  deleteUserWorker,
  getUsersByQueryWorker,
  getUsersWorker,
  updateUserWorker,
  getUsersDateRangeWorker,
} from './sagas/users.saga';
import {
  fetchUser,
  sigInUser,
  signOutUser,
  updateProfileUserWorker,
  deleteAvatarWorker,
  addAvatarWorker,
  confirmEmailChangeWorker,
} from './sagas/user.saga';
import { getRolesWorker } from './sagas/roles.saga';
import { getSearchItemsWorker } from './sagas/search.saga';
import {
  changeActiveSliderAnimationWorker,
  getActiveSliderAnimationWorker,
  getSliderAnimationsWorker,
} from './sagas/sliderAnimations.saga';
import { generateInvoiceWorker, getInvoicesListWorker, removeInvoiceWorker } from './sagas/invoice.saga';
import { getParsersWorker } from './sagas/parsers.saga';

export function* sagaTreeCategoriesWatcher(): SagaIterator {
  yield takeEvery(GET_TREE_CATEGORIES_REQUEST, fetchTreeCategoryWorker);
  yield takeEvery(ADD_TREE_CATEGORY_REQUEST, addTreeCategoryWorker);
  yield takeEvery(ADD_CHAR_TO_CATEGORY, addCharToCategoryWorker);
  yield takeEvery(EDIT_CHAR_TO_CATEGORY, editCharToCategoryWorker);
  yield takeEvery(DELETE_CHAR_TO_CATEGORY, deleteCharToCategoryWorker);
  yield takeEvery(DELETE_TREE_CATEGORY, deleteTreeCategoryWorker);
  yield takeEvery(UPDATE_TREE_CATEGORY_REQUEST, updateTreeCategoryWorker);
  yield takeEvery(DISABLE_ENABLE_CATEGORY_REQUEST, disableEnableCategoryWorker);
  yield takeEvery(GET_TREE_CATEGORIES_BY_ID_REQUEST, getTreeCategoriesByIdWorker);
}

export function* sagaProductsWatcher(): SagaIterator {
  yield takeEvery(GET_PRODUCTS_REQUEST, getProductsWorker);
  yield takeEvery(GET_PRODUCT_BY_ID_REQUEST, getProductByIdWorker);
  yield takeEvery(GET_PRODUCTS_BY_QUERY_REQUEST, getProductsByQueryWorker);
  yield takeEvery(ADD_PRODUCT_REQUEST, addProductWorker);
  yield takeEvery(UPLOAD_MAIN_IMG_REQUEST, uploadMainImgWorker);
  yield takeEvery(UPDATE_PRODUCT_REQUEST, updateProductWorker);
  yield takeEvery(UPDATE_PRODUCT_DISCOUNTED_PRICE_REQUEST, updateDiscountedPriceWorker);
  yield takeEvery(DELETE_PRODUCT_REQUEST, deleteProductWorker);
  yield takeEvery(UPDATE_AVAILABILITY_PRODUCT_REQUEST, updateAvailabilityProductWorker);
  yield takeEvery(DISABLE_PRODUCT_REQUEST, disableProductWorker);
  yield takeEvery(GET_PRODUCTS_SIZES_REQUEST, getProductsSizesWorker);
  yield takeEvery(GET_PRODUCT_STATISTIC_REQUEST, getProductStatisticWorker);
  yield takeEvery(GET_DISCOUNT_INFO_REQUEST, getDiscountInfoWorker);
  yield takeEvery(GET_SHOP_LIST_REQUEST, getShopListWorker);
}

// Settings
function* sagaSettingsWatcher(): SagaIterator {
  yield takeEvery(REQUEST_SETTINGS, fetchSettingsWorker);
  yield takeEvery(GET_PARSER_SETTINGS, getParserSettingsWorker);
  yield takeEvery(GENERATE_FEED, generateFeedWorker);
  yield takeEvery(REQUEST_UPDATE_PARSER_SETTINGS, updateParserSettingsWorker);
  yield takeEvery(REQUEST_UPDATE_SETTINGS, updateSettingsWorker);
  yield takeEvery(GET_CATEGORIES_WITH_PRIORITY, getCategoriesWithPriorityWorker);
  yield takeEvery(CHANGE_CATEGORY_PRIORITY, changeCategoriesPriorityWorker);
}

// Slides
function* sagaSlidesWatcher(): SagaIterator {
  yield takeEvery(REQUEST_ADD_SLIDES, addSlideWorker);
  yield takeEvery(REQUEST_SLIDES, fetchSlideWorker);
  yield takeEvery(REQUEST_UPDATE_SLIDES, updateSlideWorker);
  yield takeEvery(REQUEST_DELETE_SLIDES, deleteSlideWorker);
  yield takeEvery(REQUEST_UPDATE_SLIDE_VISIBILITY, updateSlideVisibilityWorker);
}

// Comments
export function* sagaCommentsWatcher(): SagaIterator {
  yield takeEvery(GET_COMMENTS_REQUEST, getCommentsWorker);
  yield takeEvery(GET_COMMENTS_BY_RANGE_REQUEST, getCommentsByDateRangeWorker);
  yield takeEvery(DELETE_COMMENT_REQUEST, deleteCommentWorker);
}

// Feedbacks
export function* sagaFeedbacksWatcher(): SagaIterator {
  yield takeEvery(GET_FEEDBACKS_REQUEST, getFeedbacksWorker);
  yield takeEvery(DELETE_FEEDBACK_REQUEST, deleteFeedbackWorker);
}

//Orders
function* sagaOrdersWatcher(): SagaIterator {
  yield takeEvery(GET_ORDERS_REQUEST, getOrdersWorker);
  yield takeEvery(GET_ORDER_BY_ID_REQUEST, getOrdersByIdWorker);
  yield takeEvery(UPDATE_ORDER_REQUEST, updateOrderWorker);
  yield takeEvery(UPDATE_ORDER_PARCEL_NUMBER, updateOrderNumber);
  yield takeEvery(UPDATE_ORDER_STATUS_REQUEST, updateOrderStatusWorker);
  yield takeEvery(UPDATE_ORDER_PAYMENT_STATUS_REQUEST, updateOrderPaymentStatusWorker);
  yield takeEvery(GER_ORDERS_BY_PARAMS_REQUEST, getOrdersByParamsWorker);
  yield takeEvery(UPDATE_PRODUCT_IN_ORDER_REQUEST, updateProductInOrderWorker);
  yield takeEvery(GET_ORDERS_BY_RANGE_REQUEST, getOrdersByDateRangeWorker);
  yield takeEvery(GET_ORDERS_STATUS_BY_RANGE_REQUEST, getOrdersStatusByDateRangeWorker);
}

export function* sagaUsersWatcher(): SagaIterator {
  yield takeEvery(GET_USERS_REQUEST, getUsersWorker);
  yield takeEvery(GET_USERS_BY_QUERY_REQUEST, getUsersByQueryWorker);
  yield takeEvery(ADD_USER_REQUEST, addUserWorker);
  yield takeEvery(UPDATE_USER_REQUEST, updateUserWorker);
  yield takeEvery(DELETE_USER_REQUEST, deleteUserWorker);
  yield takeEvery(GET_USERS_DATE_RANGE_REQUEST, getUsersDateRangeWorker);
}

export function* sagaUserWatcher(): SagaIterator {
  yield takeEvery(USER_SIGN_IN_FETCHING, sigInUser);
  yield takeEvery(USER_SIGN_OUT, signOutUser);
  yield takeEvery(USER_FETCH_REQUEST, fetchUser);
  yield takeEvery(UPDATE_PROFILE_USER_REQUEST, updateProfileUserWorker);
  yield takeEvery(ADD_AVATAR_REQUEST, addAvatarWorker);
  yield takeEvery(DELETE_AVATAR_REQUEST, deleteAvatarWorker);
  yield takeEvery(CONFIRM_CHANGE_EMAIL_REQUEST, confirmEmailChangeWorker);
}

// Roles
export function* getRolesWatcher(): SagaIterator {
  yield takeEvery(GET_ROLES_REQUEST, getRolesWorker);
}

// Search
export function* sagaSearchWatcher(): SagaIterator {
  yield takeEvery(GET_SEARCH_ITEMS_REQUEST, getSearchItemsWorker);
}

export function* sagaSliderAnimationsWatcher(): SagaIterator {
  yield takeEvery(GET_SLIDER_ANIMATIONS_REQUEST, getSliderAnimationsWorker);
  yield takeEvery(GET_SLIDER_ANIMATIONS_REQUEST, getActiveSliderAnimationWorker);
  yield takeEvery(REQUEST_CHANGE_ACTIVE_SLIDER_ANIMATION, changeActiveSliderAnimationWorker);
}

// Invoice
export function* sagaInvoiceWatcher(): SagaIterator {
  yield takeEvery(GET_INVOICES_LIST_REQUEST, getInvoicesListWorker);
  yield takeEvery(REMOVE_INVOICE_REQUEST, removeInvoiceWorker);
  yield takeEvery(GENERATE_INVOICE_REQUEST, generateInvoiceWorker);
}

// Parsers
export function* sagaParserWatcher(): SagaIterator {
  yield takeEvery(GET_PARSERS_STATUSES, getParsersWorker);
}

// Category images
export function* sagaCategoryImagesWatcher(): SagaIterator {
  yield takeEvery(UPDATE_CATEGORY_IMAGES_REQUEST, updateCategoryImagesWorker);
}

// RootSaga
export default function* rootSaga(): SagaIterator {
  yield all([
    fork(sagaTreeCategoriesWatcher),
    fork(sagaProductsWatcher),
    fork(sagaSettingsWatcher),
    fork(sagaSlidesWatcher),
    fork(sagaOrdersWatcher),
    fork(sagaCommentsWatcher),
    fork(sagaFeedbacksWatcher),
    fork(sagaUsersWatcher),
    fork(sagaUserWatcher),
    fork(getRolesWatcher),
    fork(sagaSearchWatcher),
    fork(sagaSliderAnimationsWatcher),
    fork(sagaInvoiceWatcher),
    fork(sagaParserWatcher),
    fork(sagaCategoryImagesWatcher),
  ]);
}
